<template>
    <div class="subject-property-box">
        <div class="row subject-row">
            <span class="title">Subject Property</span>
            <el-input class="pc" placeholder="Search for subject property in LINK" prefix-icon="el-icon-search" v-model="search" @change="searchInfor"></el-input>
            <a class="create-subject" @click="createSubjectProperty">+ Create Subject Property</a>
        </div>
        <div class="move"><el-input placeholder="Search for subject property in LINK" prefix-icon="el-icon-search" v-model="search"></el-input></div>
        <div class="table">
            <el-table ref="multipleTable"  :data="data" tooltip-effect="dark" style="width: 100%" @row-click="clickRow" @selection-change="handleSelectionChange" v-loading="loading">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column label="LINK ID" width="120">
                <template slot-scope="scope">{{ scope.row.ListingId }}</template>
                </el-table-column>
                <el-table-column label="STATUS" width="120">
                    <template slot-scope="scope">
                        <span class="sold" v-if="scope.row.StandardStatus==='Closed'">Sold</span>
                        <span class="available" v-else>Available</span>
                    </template>
                </el-table-column>
                <el-table-column label="PRICE">
                    <template slot-scope="scope">
                       <span>{{ scope.row.ClosePrice==null ||!scope.row.ClosePrice ? scope.row.ListPrice : scope.row.ClosePrice | currencyPrice("$ ",0)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="AREA">
                    <template slot-scope="scope">
                       <span>{{ scope.row.MLSAreaMajor ? scope.row.MLSAreaMajor : ""}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="ADDRESS">
                    <template slot-scope="scope">
                       <span> {{ scope.row.StreetNumber?scope.row.StreetNumber :""}} {{ scope.row.StreetName ?scope.row.StreetName:"" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="PROPERTY TYPE">
                    <template  slot-scope="scope">
                        <span class="item-title" v-if="scope.row.PropertyType==='Commercial'">Commercial</span>
                        <span class="item-title" v-else-if="scope.row.PropertyType==='1 Family'||scope.row.PropertyType==='Single Family'">Single Family</span>
                        <span class="item-title" v-else-if="scope.row.PropertyType==='Land'">Land</span>
                        <span class="item-title" v-else>Condo/Multi Family</span>
                    </template>
                </el-table-column>
            </el-table>
            <Page  :total="total" :pageSize="25" @number="pagesChanged" :currentPage="current"></Page>
        </div>
        <div class="btn">
            <p class="btn-box row" @click="next"><span>Next Step</span><i class="el-icon-right"></i></p>
        </div>
    </div>
</template>
<script>
import { apiGetLinkListings } from '../../API/api';
import Page from '../common/Paging'
export default {
  components: { Page },
    data(){
        return {
            loading:false,
            search:"",
            checkedGh: [],
            limit: 25,
            current: 1,
            data: [],
            total: 0
        }
    },
    computed:{
        offset(){ return (this.current- 1) * this.limit;},
        query(){
            return {
                limit: this.limit,
                offset: this.offset,
                // status: "A",
                self: true,
                search: this.search,
            }
        }
    },
    methods:{
        searchInfor(){
          this.getLinkListings()
        },
        createSubjectProperty(){
            this.$router.push("/property-detail")
            this.$store.commit("setCmaPDFUrl", "");
            this.$store.commit("setLinkId", null);
            this.$store.commit("setlinksAllId", []);
            this.$store.commit("setCompetitiveListings", []);
            this.$store.commit("setSaleListings", []);
            this.$store.commit("setCompetitiveFilterInfo", []);
            this.$store.commit("setSaleFilterInfo", []);
            this.$store.commit("setSaleFilterCondition", {});
            this.$store.commit("setCompetitiveFilterCondition", {});
            this.$store.commit("setSaleNumber", null);
            this.$store.commit("setCompetitiveNumber", null);
        },
        clickRow(row){
           this.$refs.multipleTable.toggleRowSelection(row)
        },
        // 单选框选中数据
        handleSelectionChange(selection) {
            this.checkedGh = selection[0];
            if (selection.length > 1) {
                this.$refs.multipleTable.clearSelection();
                this.$refs.multipleTable.toggleRowSelection(selection.pop());
            }
            if(this.checkedGh&&this.checkedGh.ListingId){
                 this.$store.commit('setLinkId', this.checkedGh.ListingId);
             }
            
        },
        pagesChanged(number){
            this.current = number;
            this.getLinkListings();
        },
        getLinkListings(){
            this.loading = true
            apiGetLinkListings(this.query).then(res=>{
                this.total = res.count;
                this.data = res.results;
            }).finally(()=>{this.loading = false;})
        },
        next(){
        if(this.checkedGh.ListingId){
            const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.2)'
            });
            setTimeout(() => {
            loading.close();
            }, 2000);
            this.$emit("updateTab","")
           this.$router.push('/property-detail/' + this.checkedGh.ListingId);
           }
        }
    },
    created(){
        this.getLinkListings();
    }
}
</script>
<style lang="scss" scoped> 
 @import "../../css/table.scss";
 .subject-property-box{
     margin-bottom: 60px;
    .subject-row{padding:20px 20px 10px 20px;
      .title{font-family: 'Roboto-Bold';font-size: 18px;display: inline-block;width: 280px;text-align: left;}
      .create-subject{font-family: 'Roboto-Bold';width: 350px;text-align: right;text-decoration: underline;color: #72949D;font-size: 14px;display: inline-block;margin-left: 10px;cursor: pointer;}
      /deep/ .el-input{min-width: 200px;}
    }
    .table{padding-bottom: 60px; position: relative;
        &::after{
            position: absolute;
            content: "";
            border-bottom: 1px solid #E1E9EF;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
    .btn{display: flex;-webkit-justify-content: flex-end;justify-content: flex-end;padding-right:20px;
    .btn-box{width: 95px;background-color: #678993;height: 36px;line-height: 36px;color: #fff;justify-content: space-between;padding: 0 20px;border-radius: 2px; cursor: pointer;}
    }
    .sold{background-color: rgba(103,137,147,.1);padding: 3px 10px;margin: 10px 0;display: inline-block;border-radius: 4px;color: #678993;}
    .available{ background-color: rgba(55,160,0,.1);padding: 3px 10px;margin: 5px 0;display: inline-block;border-radius: 4px; color: #37A000; }
    .move{display: none;padding:0px 20px 10px 20px;}
    @media (max-width:992px) {
     .pc{display: none;}
     .move{display: block;}
    }
 }
</style>